export const hero = {
    title: `The Top POS System in Atlanta`,
    paragraphs: [
        `Atlanta is a hotspot for its Southern hospitality, rich and diverse culture, and booming entertainment industry. Its amazing food, arts community, and endless business opportunities make it a thriving city that needs tech solutions to match. As one of the fastest-growing metros in America, ATL has plenty of opportunities to grow and expand your business.`,
        `Atlanta businesses are turning to SpotOn’s POS system and software to accept payments, build customer loyalty, track customer purchasing habits, streamline business operations, create targeted marketing, and get real-time reports.`,
      ],
  };

  export const textWithVideoAndImage = {
    heading: "Real users rate SpotOn as the #1 POS system",
    paragraph: "According to real user reviews submitted to Capterra, SpotOn is the top-rated point-of-sale solution for Restaurants, Bars, Retail, and Small Business. We are proud to have received the highest marks among our competition across all categories measured, including functionality, ease of use, customer support, and value for money.",
    thumbnailImageName: "",
    videoId: "",
    imageName: "locations-atlanta.png",
  };
  
  export const pros = {
    heading: `POS solutions for restaurants, bars, retail, and enterprise businesses`,
    paragraphs: [
      `Whether you are operating a restaurant in Buckhead or a furniture shop on Ponce, you need a POS system built for the specific needs of your business, clientele, and location. And if you sell at one of the many markets or pop-up shops across the city, you’ll want a mobile device that can handle ordering and payment processing on the go. No matter the size and scale of your business, the suite of tools from the SpotOn POS system can be customized to meet your needs.`,
      `SpotOn’s POS system in Atlanta, GA, provides flexible options to small businesses, including:`,
    ],
    bullets: [
      `Restaurants–from fine dining to quick service`,
      `Bars, breweries, distilleries, and nightclubs`,
      `Retail, from clothing to home decor`,
      `Food trucks`,
      `Local services such as automotive, health, and beauty`,
      `Professional services like accounting`,
      `Enterprise businesses like sports and entertainment venues, educational campuses, amusement and attractions, and so much more`,
    ],
    imageName: "locations-atlanta-2.png",
  };
  
  export const pros2 = {
    heading: `SpotOn POS solutions in Atlanta: Helping restaurants deliver amazing guest experiences`,
    topText: `A flexible, end-to-end <a href="/restaurant-pos" class="text-primary">Restaurant POS</a> system that is fully customizable can be the difference between your restaurant and your competitors. With SpotOn Restaurant POS software, your restaurant can:`,
    bottomText: ``,
    bullets: [
      `Track open tables, make reservations, and manage a digital waitlist`,
      `Make menu modifications and track item availability`,
      `Accept all types of payments with secure payment processing (including mobile and contactless payment options)`,
      `Create and communicate happy hour discounts and other deals`,
      `Build a book of “regulars” with a loyalty program`,
      `Generate real-time labor and sales reports`,
      `Understand most popular dishes`,
      `Schedule, tip, and manage employees`,
    ],
    imageName: "locations-atlanta-3.png",
  };
  
  export const footer = {
    heading: `If you’re looking for POS solutions in Atlanta and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a free demo`,
    title: ``,
    paragraphs: [
      `To <a href="/demo" class="text-primary">learn more</a> about how SpotOn’s POS for restaurants can help your business, contact us today for a free demo. Upon sign up, SpotOn provides new Atlanta restaurants (and other select cities) with a free photoshoot through Snappr, a nationwide network of photographers that serve enterprise clients like Doordash, Toast, PopMenu, and Bentobox. <a href="https://spotonteam.atlassian.net/wiki/spaces/SPO/pages/2118483969/Snappr+Custom+Photography+-+Pilot" target="_blank" class="text-primary">More information on the Snappr & SpotOn partnership here.</a>`,
    ],
    cta: {
      target: "#form",
      title: "Learn more",
    },
  };
  
  export const terms = {
    title: "Snappr terms & conditions.",
    paragraphs: [
      `The Snappr promotion offered through SpotOn is only available to those customers purchasing for their own personal or their company's use in the cities of Atlanta, Georgia, San Francisco, California, and San Diego, California, from 7/1/2022 to 12/31/2022. These images produced during the promotion are intended for personal or company promotional use and not intended for any sort of resale. All promotions are non-transferrable. Promotional offers end at 11:59 PM on the expiration date unless indicated otherwise and are available only to U.S. residents, exclusively provided through the Snappr photography company.`,
      `Any discounts, prizes, or promotional considerations applicable to this order apply only to the initial purchase and purchase price. They are not applicable to other charges, including: previous purchases, current or existing monthly fees, unpaid balances, or any future charges related to the product(s).`,
    ],
  };
  