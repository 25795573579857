import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../../components/Layout/layout";
import ogImage from "../../../../images/global_assets/og-image.png";
import SEO from "../../../../components/seo";
import Section from "../../../../components-v2/Base/Section";
import LeadForm from "../../../../components/Forms/LeadForm";
import Heading from "../../../../components-v2/Base/HeadingBuilder";
import Image from "../../../../components/ImageQuerys/LargeFeaturesImages";

import data, { dataWithLineOfBusiness } from "../../../../data/forms/lead-form";

import formBg from "../../../../images/svg/vector-10.svg";
import {
  hero,
  quoteWithVideo,
  textWithVideoAndImage,
  imageWithText,
  pros,
  mainFeature,
  pros2,
  footer,
  terms,
} from "../../../../data/locations/atlanta";
import check from "../../../../images/svg/checkmark.svg";
import OverviewFooter from "../../../../components-v2/OverviewFooter";
import { lineOfBusiness } from "../../../../constants";

const VideoQuote = loadable(() =>
  import("../../../../components-v2/VideoQuote")
);
const TextWithVideoAndImage = loadable(() =>
  import("../../../../components-v2/TextWithVideoAndImage")
);
const ImageWithText = loadable(() =>
  import("../../../../components-v2/ImageWithText")
);
const BulletsWithHeroImage = loadable(() =>
  import("../../../../components-v2/BulletsWithHeroImage")
);

const formId = "bfb960c4-0b53-4ec4-bcdf-4d5ae5778a78";
const thankYouPath = "/location/new-york/pos-systems/thank-you";

const formData = [...data].splice(0, data.length - 1);

const AtlantaPage = () => {
  return (
    <Layout
      isLanding
      useNewLPHeader
      className="non-fixed-width"
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="POS Systems in Atlanta | Point of Sale for Restaurants, Bars & Retail | SpotOn"
        description="SpotOn is a top provider of POS Systems in Atlanta, GA. Most reliable point of sale systems for Restaurants, Retail or Enterprise businesses. Get a demo!"
        image={`https://spoton.com/${ogImage}`}
      />

      <Section
        className={`
        flex flex-col xl:flex-row justify-between items-center
        mt-32 gap-x-10 md:gap-y-20
      `}
      >
        <div className="xl:basis-[50%] max-w-[600px] lg:max-w-[800px] xl:max-w-[400px] relative">
          <Heading level={1} injectionType={2} withBlueSymbol>
            {hero.title}
          </Heading>

          {hero.paragraphs.map((text) => (
            <p key={text} className="text-black-300 text-p">
              {text}
            </p>
          ))}
        </div>
        <div className="w-full xl:basis-[50%] max-w-[580px] relative" id="form">
          <img
            alt="background"
            src={formBg}
            className={`
            absolute left-0 top-20 md:top-10 right-0
            w-full h-auto
          `}
          />
          <LeadForm
            className="max-w-[390px] mx-auto"
            formId={formId}
            // leadType={leadType}
            leadType="san-diego-page"
            dataLayerEventNameAppendix="-lead"
            formName="Lead Form"
            formData={data}
            thankYouPath={thankYouPath}
            formItemsToSend={["firstname", "email", "phone", "company"]}
            noHeader
            plain
            lineOfBusiness={lineOfBusiness.RETAIL_AND_SERVICES}
          />
        </div>
      </Section>

      <TextWithVideoAndImage
        className="mt-40 md:mt-20"
        {...textWithVideoAndImage}
        withImageOnly
      />

      <BulletsWithHeroImage className="mt-20" {...pros} />

      <Section
        className={`
          flex flex-col lg:flex-row justify-between items-center
          gap-10 lg:gap-16 xl:gap-[120px] mt-16 md:mt-20
        `}
      >
        <div className="w-full">
          <Image imageName={pros2.imageName} />
        </div>

        <div className="w-full">
          <Heading
            level={3}
            injectionType={3}
            withBlueSymbol
            className="md:max-w-[310px]"
          >
            {pros2.heading}
          </Heading>
          <p
            className="text-p text-black-300 my-8 "
            dangerouslySetInnerHTML={{ __html: pros2.topText }}
          />
          <ul className="pl-0 list-none max-w-[430px]">
            {pros2.bullets.map((bullet) => (
              <li
                key={bullet}
                className="text-black text-p mt-6 flex items-center"
              >
                <img alt="checkmark" src={check} className="mr-4" />
                <span>{bullet}</span>
              </li>
            ))}
          </ul>
          <p
            className="text-p text-black-300 mt-8 max-w-[430px]"
            dangerouslySetInnerHTML={{ __html: pros2.bottomText }}
          />
        </div>
      </Section>

      <OverviewFooter className="mt-20" {...footer} />

      <Section className="text-[14px] text-black-300 mt-20">
        <div className="text-[18px] font-bold text-center mx-auto text-[#000000]">
          {terms.title}
        </div>
        {terms.paragraphs.map((text) => (
          <p key={text} className="mt-3 text-center mx-auto max-w-[1040px]">
            {text}
          </p>
        ))}
      </Section>
    </Layout>
  );
};

export default AtlantaPage;
